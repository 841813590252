import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, FieldArray, useField } from "formik";
import _ from "lodash";
import { Stack } from "react-bootstrap";

import { UserProjectValue } from "../../../api";

interface UserProjectsFieldProps {
  projectNames?: string[];
}

export const UserProjectsField = ({
  projectNames = [],
}: UserProjectsFieldProps) => {
  const name = "projects";
  const meta = useField(name)[1];
  return (
    <>
      <label className="form-label">Projects</label>
      <div className="border p-3">
        <FieldArray name={name}>
          {({ remove, push }) => (
            <div>
              {/* eslint-disable-next-line */}
              {meta.value.map((_item: any, index: number) => (
                <div className="mb-3" key={index}>
                  <Stack direction="horizontal" gap={3}>
                    <div className="w-100">
                      <label
                        className="visually-hidden"
                        htmlFor={`${name}.${index}.key`}
                      >
                        Key
                      </label>
                      <Field
                        name={`${name}.${index}.key`}
                        placeholder="Key"
                        type="text"
                        className="form-control form-control-sm py-0"
                        list="projectNameOptions"
                      />
                      <datalist id="projectNameOptions">
                        {_.map(projectNames, (projectName) => (
                          <option key={projectName} value={projectName} />
                        ))}
                      </datalist>
                    </div>
                    <div className="w-100">
                      <label
                        className="visually-hidden"
                        htmlFor={`${name}.${index}.value`}
                      >
                        Value
                      </label>
                      <Field
                        name={`${name}.${index}.value`}
                        placeholder="Value"
                        as="select"
                        className="form-select form-select-sm"
                        aria-label="Project value"
                      >
                        <option value="">Select</option>
                        <option value={UserProjectValue.ReadOnly}>
                          Read only
                        </option>
                        <option value={UserProjectValue.ReadWrite}>
                          Read and write
                        </option>
                        <option value={UserProjectValue.True}>True</option>
                      </Field>
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-sm"
                      onClick={() => remove(index)}
                      aria-label="Remove project"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </Stack>
                  <ErrorMessage
                    name={`${name}.${index}.key`}
                    component="div"
                    className="d-block invalid-feedback"
                  />
                  <ErrorMessage
                    name={`${name}.${index}.value`}
                    component="div"
                    className="d-block invalid-feedback"
                  />
                </div>
              ))}
              <button
                type="button"
                className="btn btn-outline-primary btn-sm"
                onClick={() => push({ key: "", value: "" })}
              >
                Add
              </button>
            </div>
          )}
        </FieldArray>
      </div>
    </>
  );
};
