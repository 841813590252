import _ from "lodash";
import { useContext, useEffect, useState } from "react";

import { ApiContext } from "../../../api";
import { UserForm } from "./user-form.component";

export const ConnectedUserForm = (
  props: React.ComponentProps<typeof UserForm>
) => {
  const { Projects } = useContext(ApiContext);
  const [projectNames, setProjectNames] = useState<string[]>([]);
  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      try {
        const value = await Projects.fetch({ signal: abortController.signal });
        setProjectNames(value);
      } catch (error) {
        if (_.get(error, "name") !== "AbortError") {
          console.error(error);
        }
      }
    })();
    return () => abortController.abort();
  }, [Projects]);
  return <UserForm {...props} projectNames={projectNames} />;
};
