import { fetcher } from "../auth";
import { apiUrl } from "./utils";

interface RequestArguments {
  signal: AbortSignal;
}

export const Projects = {
  async fetch({ signal }: RequestArguments): Promise<string[]> {
    const { data } = await fetcher({ url: apiUrl("projects"), signal });
    return data;
  },
};
